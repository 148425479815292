if(window.logging) console.log('loading qb_styling');
import "./observer";
(function() {
    const textContent = node => {
        let text = '';
        Array.from(node.childNodes).find(n=>{
            if(n.nodeType == 3) {
                text += n.textContent;
                // n.remove();
            }
            return n.nodeType == 1;
        });
        return text;
    };
    new QBObserver().subtrees(
        'h3:not([data-qbtitle]),h4:not([data-qbtitle]),.qbtitle:not([data-qbtitle])',
        node => {
            const text = textContent(node);
            if (!text) return;

            // node.textContent = '';
            let target = node;
            if (node.nodeName == 'LABEL') target = cSPAN(target);
            target.classList.add('qbtitle');
            target.dataset.qbtitle = text;


        }
    ).subtrees(
        '.qbbutton',
        node => {
            if(node.querySelector('[data-qbtitle]')) return;
            const text = node.textContent;
            if (!text) return;
            node.textContent = '';
            node.append(...text.split(/\n/).map(text => cSPAN({ data: { qbtitle: text }}, text)));
        }
    );
})();

