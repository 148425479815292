import {Confetti} from "./helper/confetti";

if(window.logging) console.log('loading qb_base');
import {EmojiButton} from "@joeattardi/emoji-button";

import { QBChannel } from './channel';
import { Browser } from './helper/browser.js';


export class QBBase {
    get init() {
        document.documentElement.classList.add('QBloaded');
        window.QBresolve();
    }

    set loaded(callback) {
        if (document.readyState === "complete" || document.readyState === "loaded") callback.apply(this);
        else document.addEventListener("DOMContentLoaded", callback.bind(this));
    }


    constructor() {
        this.registered = [];
        try { this.role = this.attr('data-role-name', '').toLowerCase(); } catch(e) {}
        this.controller = this.attr('data-controller-name', '').toLowerCase();
        this.action = this.attr('data-action-name', '').toLowerCase();
        this.authenticityToken = meta_content('csrf-token');
        this.app_id = this.randomId(6);
        this.lang = document.documentElement.lang;

        this.UUID_RE = /^(.-)?([0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12})$/;

        window.ondragleave = window.ondragenter = this.dragleave;

        window.ondrop = evt => {
            if (!evt.target.matches('input')) evt.preventDefault();
        }
        window.ondragend = evt => this.dragend;
        this.id = this.randomId();

        this.language_preference = [document.documentElement.lang || 'en'].concat(navigator.languages);

        this.inhibitEnterSubmit;
    }

    attr(name, substitute) {
        const attr = document.documentElement.getAttribute(name);
        return attr === undefined ? substitute : attr;
    }

    randomId(pos) {
        return (Math.floor(Math.random() * 26 + 10).toString(36) + Math.floor(Math.random() * 36 ** (pos || 1)).toString(36)).substr(0, pos);
    }

    pathname(...appends) {
        const m = document.location.pathname.match( /^(.*([0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12})?).*/);
        appends.unshift(m[1]);
        return appends.join('/').replace(/\/+/g, '/');
    }

    location(...pathname) {
        if(pathname[0] && /\//.test(pathname[0])) document.location = pathname.join('/').replace(/\/+/g, '/');
        else document.location.pathname = this.pathname(...pathname);
    }

    get reload() {
        this.silence = true;
        document.location.reload();
    }

    set reload(seconds) {
        setTimeout(()=>this.reload, seconds);
    }

    textContent(node) {
        if(!node.matches('[data-qbtitle]')) {
            const nodes = Array.from(node.querySelectorAll('.qbtitle'));
            if(nodes.length > 0) {
                node = nodes.find(n=>n.lang == this.lang) || nodes.find(n=>n.lang == 'en') || nodes[0];
            }
        }
        const textContent = [node.textContent];
        if(node.dataset.qbtitle) textContent.unshift(node.dataset.qbtitle || '');
        return textContent.join(' ').trim();
    }


    dragstart (elem, handler) {
        elem.ondragstart = handler;
    }

    dragend (evt) {
        document.querySelectorAll('.dropable, .dragover').forEach(e=>e.classList.remove('dropable','dragover'));
    }

    dragleave (evt) {
        if(evt.type != 'dragleave' || evt.target.parentNode != document.body) return;
        Array.from(document.getElementsByClassName('dragover')).forEach(e=>e.classList.remove('dragover'));
    }

    drag (evt) {
        evt.preventDefault();
        const dropable = evt.target.closest && evt.target.closest('.dropable');
        switch (dropable && evt.type) {
            case "dragover":
                Array.from(document.getElementsByClassName('dragover')).forEach(e=>e.classList.remove('dragover'));
                dropable.classList.add('dragover');
                break;
            case "drop":
            case "dragend":
                QB.dragend();
                break;
            case "dragleave":
                dropable.classList.remove('dragover');
                break;
        }
    }

    dropable (container, handler) {
        container.ondragend = container.ondragover = container.ondragleave = this.drag;
        container.ondrop = handler;
        container.classList.add('dropable');
    }


    get calendar_earlier() {
        const evt = event;
        evt.preventDefault();
        const nav = evt.target.closest("nav"),
            week = nav.nextElementSibling;
        QB.fetch(QB.pathname("before", week.dataset.week)).then(({html})=>{
            week.insertAdjacentHTML('beforebegin', html);
        });
    }
    get calendar_later() {
        const evt = event;
        evt.preventDefault();
        const nav = evt.target.closest("nav"),
            week = nav.previousElementSibling;
        QB.fetch(QB.pathname("after", week.dataset.week)).then(({html})=>{
            week.insertAdjacentHTML('afterend', html);
        });
    }



    clickStep(evt) {
        const target_container = evt.target.closest('.QB-Teams, .QB-Tickets');
        if(!target_container) return;
        const next_container = target_container.nextElementSibling,
            input = target_container.querySelector('input:checked');
        let ticket;
        if(input) {
            target_container.classList.add('done');
            ticket = next_container.querySelector('input[value="' + input.value + '"]');
            if (!next_container.querySelector('input[type="radio"]')) next_container.classList.add('done');
        }
        else {
            target_container.classList.remove('done');
            next_container.classList.remove('done');
            (target_container.querySelector('input[type="radio"]:checked') || {}).checked = false;
        }
        if(!ticket) ticket = next_container.querySelector('[id^="charge_pool_id"]');
        if(ticket) ticket.click();
    }



    rebookPools(evt) {
        const target = evt.target,
            my_pool = document.querySelector('#QB-CreditPools fieldset.me'),
            target_pool = target.parentNode,
            target_pool_id = target_pool.getAttribute('data-pool'),
            last_value = parseInt(target.getAttribute('data-transferable')),
            current_value = parseInt(target.value),
            transfer = current_value - last_value;

        const my_transferable = my_pool.querySelector('.transferable'),
            my_pool_total = my_pool.querySelector('.total'),
            pool_transferable = target_pool.querySelector('.transferable'),
            pool_total = target_pool.querySelector('.total'),
            transferable = parseInt(target.max) - current_value;

        pool_transferable.textContent = current_value;
        pool_total.textContent = current_value + parseInt(pool_total.getAttribute('data-not_transferable'));
        my_transferable.textContent = transferable;
        my_pool_total.textContent = transferable + parseInt(my_pool_total.getAttribute('data-not_transferable'));


        if(evt.type == 'change') {
            console.log(transfer);
            this.fetch('/credits/pool',{ method: 'post', params: {
                    transfer: current_value - last_value,
                    pool_id: target_pool_id
                }
            }).then(data=>{
                const other_pools = document.querySelectorAll('#QB-CreditPools fieldset.pools:not(.' + target.id + ') input');
                other_pools.forEach(p=>p.max = parseInt(p.value) + transferable);
            });
        }
    }
    emoji_picker(element) {
        if(typeof element == "string") element = document.getElementById(element);
        const picker = new EmojiButton();
        picker.on('emoji', selection => {
            element.innerHTML = selection.emoji;
            element.onchange(element);
            picker.destroyPicker();
        });
        picker.wrapper.style.zIndex = 100;
        picker.togglePicker(element);
    }

    get persistCaret() {
        const selection = window.getSelection(),
            {anchorNode, anchorOffset, focusNode, focusOffset} = selection;
        event.target.caret = [anchorNode, anchorOffset, focusNode, focusOffset];
    }


    emoji_insert(input) {
        if(typeof input == "string") input = document.getElementById(input);
        let setCaret=()=>input.focus();
        if('caret' in input) {
            const selection = window.getSelection();
            setCaret=()=>{
                input.focus();
                selection.setBaseAndExtent(...input.caret);
            };
        }
        const picker = new EmojiButton();
        picker.on('emoji', selection => {
            picker.destroyPicker();
            setTimeout(() => {
                setCaret();
                document.execCommand("insertText", false, selection.emoji);
            }, 10);

        });
        picker.wrapper.style.zIndex = 100;
        picker.togglePicker(input);
    }

    update_avatar(evt) {
        QB.fetch('/identity/avatar').then(({html})=>{
            QB.overlay(html);
        });
    }


    nicknameAvatarColor(elem) {
        const options = {
                method: 'put',
                params: { identity: {  } }
            },
            identity = options.params.identity;
        switch (elem.getAttribute('name')) {
            case 'identity[nickname]':
                identity.nickname = elem.value;
                break;
            case 'identity_chooser':
                identity.avatar = elem.innerText;
                break;
            case 'identity[color]':
                identity.color = elem.value;
                break;
            case 'identity[newsletter]':
                identity.newsletter = elem.value;
                break;
            default:
                return;
        }

        QB.fetch('/identity', options);
    }


    get playingInTeamId() {
        try {
            return document.querySelector("input[name='team_id']:checked").value;
        } catch(e) {}
    }

    get myIdentityId() {
        try { return this.myIdentity.id } catch (e) {}
    }



    callForHelp(action) {
        if(event) event.preventDefault();
        this.fetch('./help', {params: { help_for_action: action }}).then(data => {
            this.overlay(data.content);
            if (data.js) this.execJS(data.js);
        }).catch(e=> {
            this.fetch('/help').then(data => {
                this.overlay(data.content);
                if (data.js) this.execJS(data.js);
            });
        });
    }


    toFunction(js)  { return  new Function('"use strict";return ' + js) }
    execJS(js)        { return this.toFunction(js).bind(this)() }


    set timer(seconds) {
        this.timer.duration = seconds;
    }
    get timer() {
        return document.getElementById('question-timer').helper;
    }

    toggleCombinationQuestions(evt) {
        const task = document.body.getAttribute('data-todo');
        if (task == 'combination') document.body.removeAttribute('data-todo');
        else document.body.setAttribute('data-todo', 'combination');
    }
    toggleUnfinishedQuestions(evt) {
        Array.from(document.querySelectorAll('.todo')).forEach(s => s.classList.remove('todo'))
        Array.from(document.querySelectorAll('input, [contenteditable]'))
            .filter(i => /^\s*$|\?/.test(i.value || i.innerText) && i.closest('section'))
            .forEach(i => {
                const section = i.closest('section');
                section.classList.add('todo');
                section.parentNode.classList.add('todo');
            });
        const task = document.body.getAttribute('data-todo');
        if (task == 'todo') document.body.removeAttribute('data-todo');
        else document.body.setAttribute('data-todo', 'todo');
    }



    purchase(evt) {
        evt.preventDefault();
        const {promise, resolve} = Promise.create();
        if(!window.Stripe) cT("script", {src: 'https://js.stripe.com/v3/', append: document.head, onload: resolve});
        else resolve();
        promise.then(()=> {
            const stripe = Stripe(evt.target.getAttribute('data-stripe')),
                form = evt.target;
            this.fetch(form.action, {method: "POST", formdata: new FormData(form) })
                .then(session => session.id ? stripe.redirectToCheckout({sessionId: session.id}) : session)
                .then(result => QB.error = result.error && result.error.message)
                .catch(error => console.error("Error:", error));
        });
    }

    selectTip(evt) {
        evt.preventDefault();
        const elem = evt.target.closest('button'),
            target = elem.parentNode.querySelector('#tip');
        target.value = elem.value;
    }

    tip(evt) {
        const form = evt.target,
            data = Object.fromEntries(new FormData(form).entries());
        form.tip.value = data.tip = parseInt(data.tip);
        if(isNaN(data.tip)) {
            evt.preventDefault();
            return;
        }
        if(this.action == 'show') return;
        evt.preventDefault();
        this.fetch(form.action, { method: "POST", params: data})
            .then(data => form.tip.value = '');
    }

    get inhibitEnterSubmit() {
        window.addEventListener('keydown',evt => {
            return evt.code != 'Enter' && evt.target.classList.contains('entersubmit')
        });
    }


// @todo refac to QBIdentity
    set myIdentity(identity) {
        console.log(identity);
        this._myIdentity = identity;
        this._myIdentity.in_team = (team_id) => { return this._myIdentity.team_ids.indexOf(team_id) > -1 }
        // this._myIdentity.in_slot = (index) => { return this._myIdentity.team_ids.indexOf(team_id) > -1 }
    }

    get myIdentity() { return this._myIdentity }

    meInTeam (team_id) { return this._myIdentity.team_ids }

    get cssStyleSheet () {
        return this._cssStyleSheet || (this._cssStyleSheet = cSTYLE({append: document.head}).sheet);
    }

    get cssRules () {
        return this._cssRules || (this._cssRules = {});
    }

    setStyle(selector, style, sheet) {
        if(!sheet) sheet = QB.cssStyleSheet;
        if(this.cssRules[selector] === undefined) {
            sheet.insertRule(`${selector} {${style}}`);
            QB.cssRules[selector] = [style, sheet.cssRules[0]];
        }
        else if (QB.cssRules[selector][0] != style) QB.cssRules[selector][1].style = style;
    }


    get getRange() {
        const selection = window.getSelection(),
            range = selection.getRangeAt(0);
        return {
            range: range,
            startNode: range.startContainer.childNodes[range.startOffset],
            endNode:  range.endContainer[range.endOffset]
        };
    }


    details_close(evt) {
        const target = evt.target.closest('details');
        target.parentNode.querySelectorAll('details').forEach(d=> { if(d != target) d.open = false } )
    }

    urlWithSearchParams(options, path) {
        const url = new URL(document.location);
        if(path) url.pathname = path;
        url.search = new URLSearchParams();
        for (const key in options) url.searchParams.append(key, options[key]);
        return url;
    }

    form_submit(form, options) {
        const {notice, reset} = options || {};
        this.fetch(form.action, {method: form.method, params: new FormData(form)})
            .then(()=>{
                if(notice) QB.notice=notice;
                if(reset) form.reset();
            });
    }

    fetch(path, options) {
        let body, { method, params, formdata } = options || {};
        if(!method) method = 'GET';

        const headers = { 'X-CSRF-Token': this.authenticityToken, 'X-APP-ID': QB.app_id };

        if(formdata) {
            body = new FormData();
            let entries;
            if(Object.type(formdata) === "FormData") entries = Array.from(formdata.entries());
            else entries = Object.entries(formdata);
            entries.forEach(([k,v]) => {
                if(Object.type(v) == "Object") {
                    Object.entries(v).forEach(([kk,v]) => {
                        body.append(`${k}[${kk}]`, v)
                    });
                }
                else body.append(k,v);
            });
        }
        else if (params instanceof FormData) {
            body = params;
            // headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        else if (method == 'GET') {
            if(params) path = this.urlWithSearchParams(params, path);
        }
        else {
            body = JSON.stringify(params);
            headers['Content-Type'] = 'application/json';
        }

        return fetch(path, {
            method: method,
            headers: headers,
            credentials: 'same-origin',
            body: body
        }).then(r=>{
            if(r.status == 302) {
                document.location = r.headers.get("X-redirect");
            }
            else if(!r.ok) {
                QB.alert = decodeURIComponent(r.headers.get('X-Error') || '') || __("Operation failed");
                throw r;
            }
            else if (r.redirected) {
                document.location = r.url;
            }
            else {
                const notice = r.headers.get('X-Notice');
                if(notice) QB.notice = decodeURIComponent(notice);
                return /json/.test(r.headers.get('Content-Type')) ? r.json() : r;
            }
        });
    }




    scrollIntoView(elem, smooth) {
        if(typeof elem == "string") elem = document.getElementById(elem.replace(/.*#/, ''));
        if(!elem || !elem.isConnected) return false;
        setTimeout(()=>{
            // const scroll = window.getScrollParent(elem);
            // const top = elem.offsetTop - 100;
            // scroll.scrollTo({behavior: smooth === false ? "auto" : "smooth", top: top})
            elem.scrollIntoView({behavior: smooth === false ? "auto" : "smooth"});
        }, 100)
        return true;
    }


    get infoContainer() {
        let container = document.querySelector('.notice-container') || cDIV({
            class: "notice-container QBv2-Notice",
            prepend: document.querySelector('body > header') || document.body
        }, cDIV());
        return container.firstElementChild;
    }

    set notice(text) {
        if(this.silence) return;
        const container = this.infoContainer;
        console.log(text);
        container.querySelectorAll('.notice').forEach(e=>{if(e.textContent == text) e.remove()});
        container.append(cDIV(text, {class: 'notice'}));
    }

    set alert(text) {
        if(this.silence) return;
        const container = this.infoContainer;
        container.querySelectorAll('.alert').forEach(e=>{if(e.textContent == text) e.remove()})
        container.append(cDIV(text, {class: 'alert'}));
    }


    pay(evt) {
        evt.preventDefault();
        const stripe = Stripe(evt.target.getAttribute('data-stripe')),
            form = evt.target;
        QB.fetch(form.action, {method: "POST", params: { plan: form.plan.value, currency: form.currency.value }})
            .then(session => session.id ? stripe.redirectToCheckout({ sessionId: session.id }) : session)
            .then(result => QB.error = result.error &&  result.error.message)
            .catch(error => console.error("Error:", error));
    };


    confirm (text, options) {
        let title;
        if(!options) options = {};
        if(options && options.title) {
            title = options.title;
            delete options.title;
        }
        else title = __("Please choose!");
        const {promise, resolve, reject} = Promise.create();
        const overlay = cDIV({ class: 'QBoverlay'}, cDIV({class: 'QBv2-CTA-Confirm'},  cT('h3', title), cDIV({class: 'poly-content'}), cDIV({class: 'message'}, text)));
        if(options.click_close) overlay.onclick = e=>{ e.target.remove();reject() }
        const dialog = cDIV({class: 'dialog', append: overlay.lastElementChild});
        if (!options || Object.keys(options).length == 0) options = { true: __('Yes'), false: __("No") };

        Object.entries(options).forEach(([rv, text])=>{
            if (rv === 'true') rv = true;
            else if (rv === 'false') rv = false;
            cBUTTON({append: dialog, type: 'button', class:`qbbutton ${rv}`, onclick: ()=>resolve(rv)}, text);
        });

        // overlay.addEventListener('focus', evt=>console.log('overlay', evt), {once: true});
        if(!document.hasFocus()) {
            window.addEventListener('focus', evt => setTimeout(() => {
                const button = overlay.querySelector("button:hover");
                if(button) button.click();
            }, 200), {once: true});
        }


        const existing = document.querySelector(".QBoverlay");
        if(existing) existing.before(overlay);
        else document.body.append(overlay);
        return promise.finally(()=>overlay.remove());
    }



// ********************************
// Cionverted from App
// todo refactorize

    get channels() { return QBChannel.subscriptions }

    selectQuizTeam (evt) {
        evt.preventDefault();
        if(QB.action == 'play') return;
        if(evt.target.closest('.no-create-teams')) return;
        const team = evt.target,
            team_id = team.value || team.getAttribute('data-team_id');

        const path = location.pathname.replace(/([0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}).*$/, "$1/teams/select")

        QB.fetch(path, { params: {team_id: team_id}, method: "post"}).catch(e => console.log(e));
        if (team.id == "member_in_teams") team.value = '';
    }

    overlay(...args) {
        const overlay = document.getElementsByClassName('overlay')[0],
            callbacks = args.filter(a=>typeof(a) == 'function'),
            insert = overlay ? {before: overlay} : {append: document.body};
        const container = cDIV(insert, ...args.filter(a=>typeof(a) !== 'function'));
        container.classList.add('overlay');
        container.addEventListener('click', evt=>{
            if(evt.target !== container) return;
            callbacks.forEach(c=>c(container));
            evt.target.remove();
        });
        return container.firstChild;
    }

    playerTag (identity_id, tag) {
        return cT(tag ||'span', {class: 'player i' + identity_id}, cSPAN({class: 'avatar'}), cSPAN({class: 'name'}));
    }


    set clipboard (text) { this.copyText(text); }

    copyNode(node) {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(node.textContent).then(c=>{
                QB.notice = __("Copied: %{text}", {text: c});
                return c;
            });
        }

        const selection = getSelection();
        if (selection == null) return Promise.reject(new Error());

        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.addRange(range);
        document.execCommand('copy');
        QB.notice = __("Copied: %{text}", {text: selection.toString()});
        selection.removeAllRanges();
        return Promise.resolve();
    }

    copyText(text) {
        if ('clipboard' in navigator) return navigator.clipboard.writeText(text);
        const node = cT('pre', {style: "width:1px;height:1px;position:fixed;top:5px;", append: document.body}, text);
        this.copyNode(node);
        node.remove();
        return Promise.resolve();
    }

    get enterSubmit() { event.code == 'Enter' && event.target.blur() }
    get enterTeamCode() {
        const evt = event;
        evt.preventDefault();
        const code = evt.target.value.toUpperCase().replace(/[^A-Z0-9]+/g,'');
        evt.target.value = code;
        if (!code) return;

        QB.fetch(QB.pathname("teamcode"), {
            method: 'put',
            params: { code: code }
        }).then(({team_id, html})=>{
            let input = document.getElementById(`team_id_${team_id}`);
            if(input) {
                input.scrollIntoView({behavior: "smooth", block: "center"});
                return input.click();
            }
            evt.target.closest('li').insertAdjacentHTML('afterend', html);
            input = document.getElementById(`team_id_${team_id}`);
            input.click();
            cHiddenField('code[]', code, {append: input.form});

        }); //.finally(e=>evt.target.value = '');
    }


    get teamCodeCopy() {
        if(event) event.preventDefault();
        const code_element = document.getElementById('QB-code');
        this.clipboard = code_element.innerText.replace(/\s+/g,"");
    }


    teamCode() {
        if(event) event.preventDefault();
        const code_element = document.getElementById('QB-code'),
            code_container = code_element.parentNode;

        this.fetch(action, {method: 'post'}).then(data => {
            const code = data.join_code.code;
            code_element.textContent = '';
            const textIter = code[Symbol.iterator]();
            let key = textIter.next();
            while (!key.done) {
                const li = document.createElement('li');
                li.append(key.value);
                code_element.appendChild(li);
                key = textIter.next();
            }
            const expires_at = code_container.querySelector('time');
            if(expires_at) expires_at.helper.datetime = data.join_code.expires_at;
            const mailto = document.querySelector('a[href^="mailto:"][href*="booking%2F"]');
            if(mailto) mailto.href = mailto.href.replace(/booking%2F[a-zA-Z0-9]+/, 'booking%2F' + code);

        });
    }

    get footer_click() {
        const evt = event;
        if(!evt.target.closest('footer > div > button, footer > div > label, summary')) return;
        const details = evt.target.closest('details');
        if(!details) return;
        const footer = details.closest('footer');
        if(footer) footer.querySelectorAll('details').forEach(e=>{if(e!=details) e.open=false})
    }



    translatable(text, options) {
        if(typeof text == 'object') {
            const container = cDIV();
            Object.entries(text).forEach(([l,t])=>container.append(cSPAN({lang: l}, t, options)));
            return container.innerHTML;
        }
        else return cSPAN(text, options).outerHTML;
    }



    get infinite_scroll_init() {
        const tasklist = document.querySelector('main .tasklist'),
            observe = ()=>{
                const limit = tasklist.offsetHeight - window.innerHeight,
                    tasks = Array.from(tasklist.querySelectorAll("div.task")),
                    object = tasks.find(e=>e.offsetTop > limit) || tasks.pop();
                this.infinite_scroll_observer.observe(object);
            };
        this.infinite_scroll_observer = new IntersectionObserver((entries, observer) => {
            for(let i=0,n=entries.length;i<n;i++) {
                const entry = entries[i];
                if(entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    const children = Array.from(tasklist.querySelectorAll('.task'));
                    QB.fetch(new URL(document.location), {
                        method: 'PUT',
                        params: { o: children.length, n: children.map(n=>n.id).filter(n=>n) }
                    }).then(r=>r.text()).then(html => {
                        const elements = cDIV(html);
                        if(!elements.querySelector('div.task')) return;
                        tasklist.append(...elements.children);
                        observe();
                    });
                    return;
                }
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.01
        });
        observe();

    }



    get init_filter() {
        const filter = document.getElementById('QB-Filter');
        filter.classList[document.location.search ? 'add' : 'remove']('active');
        // filter.querySelector('.close').onclick = () => {
        //     Array.from(filter.querySelector('form').elements).forEach(n=>n.value='');
        //     this.filter;
        // }
    }

    get task_filter() {
        const filter = document.getElementById('QB-Filter'),
            form = filter.querySelector('form'),
            terms =  Array.from(new FormData(form).entries()).map(([k,v])=>v),
            term = {s: terms.join('').replace(/\s+/g, ' ').trim()};
        if(!term.s) delete term.s;
        const location = QB.urlWithSearchParams(term);
        if(event.shiftKey || location.href != document.location.href) document.location = location;

    }


    dynamicHeader() {
        const nav = document.body.querySelector('body > header > nav');
        if (!nav) return;
        let lastPageYOffset = 0,
            lastDir = -1,
            scrollTop = window.pageYOffset,
            ticking = false;

        const header = nav.parentNode,
            menu = header.querySelector('svg.QBmenu');

        const setup = ()=>{
                header.style.position = 'fixed';
                header.style.top = '0';
                header.style.left = '0';
                header.style.right = '0';
                header.style.transition = 'all 0.1s linear';
            },
            scroll = (evt) => {
                const dir = Math.sign(scrollTop - lastPageYOffset);
                if (scrollTop < 10) header.style.top = '0px';
                else if (lastDir != dir && dir == (lastDir += dir)) {
                    if (dir < 0) header.style.top = '0px';
                    else {
                        const {top, height} = nav.getBoundingClientRect(),
                            translateY = top + height;
                        if (menu) menu.classList.remove('show');
                        header.style.top = '-' + translateY + 'px';
                    }
                }
                lastPageYOffset = scrollTop;
            };

        setup();

        window.addEventListener('scroll', ()=>{
            scrollTop = window.pageYOffset;
            if(!ticking) window.requestAnimationFrame(()=>{
                scroll();
                ticking = false;
            });
            ticking = true;
        });
        window.addEventListener('resize', setup);
    }



    get setLocale() {
        QB.fetch('/locale/' + event.target.closest('[lang]').getAttribute('lang')).then(()=>document.location.reload());
    }

    get toggleScreenStyle() {
        const value = document.documentElement.dataset.style == "dark" ? "light" : "dark";
        fetch(`/identity/style/${value}`).then(r=> {
            if (r.headers.get('X-Style')) document.documentElement.dataset.style = r.headers.get('X-Style');
            else delete document.documentElement.dataset.style;
        })
    }

    screenStyle(elem) {
        fetch(`/identity/style/${elem.value}`).then(r=> {
            if (r.headers.get('X-Style')) document.documentElement.dataset.style = r.headers.get('X-Style');
            else delete document.documentElement.dataset.style;
        })
    }

    dateSelect(which, name) {
        const value = Array.from(which.parentElement.children).map(e=>e.value),
            field = which.parentElement.parentElement.firstElementChild,
            parts = field.value.split(" "),
            key = which.name.match(/[^_]+$/)[0];
        switch(key) {
            case 'year':
            case 'month':
            case 'day':
                field.dataset.leap = new Date(parseInt(value[0]), 1, 29).getDate() === 29;
                const lastDay = new Date(parseInt(value[0]),parseInt(value[1]),0).getDate();
                if(parseInt(value[2]) > lastDay) value[2] = which.parentElement.children[2].value = `00${lastDay}`.slice(-2);
                parts[0] = value.join("-");
                break;
            case 'hour':
            case 'minute':
            case 'second':
                parts[1] = value.join(":") + ":00";
                break;
            case 'continent':
                which.nextElementSibling.value = '';
                parts[2] = value[0];
                break;
            case 'zone':
                parts[2] = value[1]
                break;

        }

        field.setAttribute('value', field.value = parts.join(' '));
    }

    vendorSelect(which) {
        const vendor_id = which.selectedOptions[0].value;
        document.querySelectorAll(`select.vendor-select`).forEach(s => {
            const option = s.querySelector('option[selected]') || s.selectedOptions[0];
            s.querySelectorAll('[selected]').forEach(c => c.removeAttribute('selected'));
            s.querySelectorAll(`optgroup[data-vendor_id="${vendor_id}"], optgroup:not([data-vendor_id])`).forEach(g =>g.classList.add('selected'));
            s.querySelectorAll(`optgroup[data-vendor_id]:not([data-vendor_id="${vendor_id}"])`).forEach(g=>g.classList.remove('selected'));
            if(option.parentElement.classList.contains('selected')) return option.selected = true;
            const alt = s.querySelector(`.selected option[value='${option.value}']`) || s.querySelector('.selected option');
            if (alt) alt.selected = true;
            else s.selectedIndex = undefined;
        });
    }

    get colors() {
        if(this._colors) return this._colors;
        const colors = Array.from(document.styleSheets).flatMap(s=>Array.from(s.rules)).filter(r=>/\[data-color=/.test(r.selectorText)).map(r=>{
            const [_, name, color] = r.cssText.match(/"([^"]+)".*(rgb\([^\)]+\))/) || [];
            if(color && name) return [name, new Color(color, name)];
        }).filter(a=>a);
        return this._colors = Object.fromEntries(colors);
    }

    get confetti() {
        if(!this._confetti) this._confetti = new Confetti();
        return this._confetti;
    }

    blast(rank) {
        const factor = Math.min(window.innerWidth, 1200) / 800,
            velocityX = Math.floor(25 * factor),
            velocityY = Math.floor(window.innerHeight / 20);
        const particles = Math.floor(([0, 750, 500, 250][rank] || 75) * factor);
        return this.confetti.blast(particles, {velocityX, velocityY});
    }

    window_open_close({href, target}) {
        event.preventDefault();
        const link = event.target.closest('[href]');
        if(link) href = link.href;
        if(link) target = link.target || target;

        const w = window.open(href, target || '_blank', "popup=1,width=310,height=500,left=0,top=0");
        window.focus();
    }
}

class Color {
    constructor(color, name) {
        if(typeof color === "string") {
            let _;
            [_, this._r, this._g, this._b, _, this._a] = Array.from(color.match(/(\d+),\s*(\d+),\s*(\d+)(,\s*(\d+))?/));
        }
        else {
            this._r = color.r;
            this._g = color.g;
            this._b = color.b;
            this._a = color.a;
        }
        this.name = name || color.name;
    }

    get r() { return parseInt(this._r) || 0 }
    set r(v) { this._r = v }
    get g() { return parseInt(this._g) || 0 }
    set g(v) { this._g = v }
    get b() { return parseInt(this._b) || 0 }
    set b(v) { this._b = v }
    get a() { return this._a == 0 ? 0 : parseFloat(this._a) || 1 }
    set a(v) { this._a = v }


    rgba(adjust) {
        return this.rgb(adjust).replace(/rgb\((.+)\)/,`rgba($1,${this.a})`);
    }
    rgb(adjust) {
        let colors = [this.r, this.g, this.b];
        if(adjust!==undefined) colors = colors.map(c=>Math.round(c * adjust));
        return `rgb(${colors.join(',')})`
    }

}



