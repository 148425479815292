import "./logger";
import "./js_extensions";
import "./tags";
import "./styling";
// import "./trix";
import {QBBase} from "./base";
window.QB = new QBBase();


import { QBObserver } from "./observer";

new QBObserver().postprocessor('body > main :not([data-show-lang]) > [lang], body > footer :not([data-show-lang]) > [lang]', nodes => {
    const parentNodes = new Set(Array.from(nodes).map(e=>e.parentNode));
    const prefs = QB.language_preference.map(l=>l.replace(/-.*$/, ''));
    const sort = (l,h) => {
        let ll = prefs.indexOf(l.lang),
            hl = prefs.indexOf(h.lang);
        if(ll < 0) ll = prefs.length + 1;
        if(hl < 0) hl = prefs.length + 1;
        return ll - hl;
    }
    parentNodes.forEach(parent=>{
        const langs = Array.from(parent.children).sort(sort);
        langs.forEach((n, i)=>{if(n.nodeName == 'DIV') n.style.order=i});
        parent.setAttribute('data-show-lang', langs[0].lang);
    });
});

new QBObserver().subtrees('.notice-container > div > *', node => node.addEventListener("animationend", evt=>evt.target.remove(), false));

import "./traces";


import { TimeHelper } from './time';
window.TimeHelper = TimeHelper;


import { QBMedia } from "./media"
QBMedia.setup;
new QBObserver().postprocessor('body > header', () => {
    const header = document.querySelector('body > header');
    if(!header) return;
    document.body.style.paddingTop = `calc(${header.getBoundingClientRect().height + 'px'} + 2em)`
});

// import("./media/image.js").then(imp => {
//     Object.values(imp).forEach(obj => obj.init);
// }).catch(err => console.error(err));


// if(/participa|player/.test(QB.role)) {
//     import( "./event/participant.js").then(({QBEventParticipant}) => QB.register("Event", new QBEventParticipant()));
// }
// else if(/master|mc/.test(QB.role) && QB.action != "index") {
//     import( "./event/mc.js").then(({QBEventMC}) => { QB.Event = new QBEventMC() });
// }
// else if(/creator|manager/.test(QB.role)) {
//     switch(QB.controller) {
//         case "tasks":
//             if(QB.action == 'index') QB.infinite_scroll_init;
//             break;
//         case "events":
//     }
//
//     import( "./edit.js").then(({QBEdit}) => QBEdit.init).catch(x=>console.error(x));
// }






