import {QBObserver} from "./observer";
const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.9
}

const params = node => {
    const trace = node.dataset.t;
    let params;
    try { params = JSON.parse(trace) } catch(e) { params = { t: trace } }
    if(typeof params == 'string') params = { t: params }
    if(!"to" in params && node.href) params.ct = node.href;
    return params;
}

const process = (node) => {
    const p = params(node),
        {promise, resolve, reject} = Promise.create(),
        to = parseInt(p.to) || 0;
    if('to' in p) {
        delete p.to;
        p.ci = "view";
    }
    const exec = ()=>{ logger.trace(p); resolve(node);}
    if(to > 0) node.to = setTimeout(exec, to * 1000);
    else exec();
    return promise;
}

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry=>{
        const node = entry.target,
            p = params(node),
            firstCall = typeof node.isIntersecting == 'undefined';
        if(node.isIntersecting = entry.isIntersecting) {
            if(!node.to) process(node).then(node=>observer.unobserve(node));
        }
        else if(!firstCall) clearTimeout(node.to);
    })
}, options);




new QBObserver().subtrees('[data-t]', node=>{
    const p = params(node);
    if(p.ci === 'click') node.addEventListener('click', evt=>{
        evt.preventDefault();
        process(node);
        setTimeout(()=>node.click(),100);
    }, {once: true});
    else observer.observe(node);
});
