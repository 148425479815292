// window.logging = true;
window.onerror = (message, url, line, column, error) => {
    const body = {message: message, url: url, line: line, column: column }
    if(error && error.stack) body.backtrace = error.stack;
    window.logger.send('exception', body);
}

window.logger = {
    send(level, ...params) {
        const oReq = new XMLHttpRequest(),
            formData = new FormData();
        formData.append('level', level);
        formData.append('message', JSON.stringify(params));
        formData.append('user_agent', navigator.userAgent);
        formData.append('location', location.toString());
        const error = new Error();
        if(error && error.stack) formData.append('stack', error.stack.replace(/^([^\n]+\n){2}/,''));
        oReq.open("post", '/errors/javascript');
        oReq.setRequestHeader('X-CSRF-Token', meta_content('csrf-token'));
        oReq.send(formData);
    },
    log(...params) { window.logger.send('info', ...params) },
    warn(...params) { window.logger.send('warn', ...params) },
    error(...params) { window.logger.send('error', ...params) },
    trace(params) {
        const request = new XMLHttpRequest();
        request.open("post", '/trace');
        request.setRequestHeader('X-CSRF-Token', meta_content('csrf-token'));
        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.send(JSON.stringify(params));
    }
}
// setTimeout(()=>logger.trace('5'), 5000);
// setTimeout(()=>logger.trace('10'), 10000);
// setTimeout(()=>logger.trace('30'), 30000);