//-----------Var Inits--------------
// <canvas id="canvas"></canvas>
// body {
// 	overflow: hidden;
// 	margin: 0;
//   background: linear-gradient(to bottom, skyblue, dodgerblue);
// }

export class Confetti {
    constructor() {
        this.colors = [];
        Object.values(Object.slice(QB.colors,
            "green1", "red1", "blue1", 
            "green2", "blue2", "brown2", "yellow2", "red2"
        )).forEach(color=>this.colors.push({front: color.rgb(), back: color.rgb(0.7)}));

        // this.colors = [
        //     {front: 'red', back: 'darkred'},
        //     {front: 'green', back: 'darkgreen'},
        //     {front: 'blue', back: 'darkblue'},
        //     {front: 'yellow', back: 'darkyellow'},
        //     {front: 'orange', back: 'darkorange'},
        //     {front: 'pink', back: 'darkpink'},
        //     {front: 'purple', back: 'darkpurple'},
        //     {front: 'turquoise', back: 'darkturquoise'},
        // ];
        // this.width = window.innerWidth;
        // this.height = window.innerHeight
        this.confetti = [];
        this._promises = [];
        this.resize;
        // window.addEventListener('resize', function () {
        //     resizeCanvas();
        // });
    }
    get count() { return this._count === undefined ? 300 : this._count }
    get gravity() { return this._gravity === undefined ? 0.5 : this._gravity  }
    get velocity() { return this._velocity === undefined ? 5 : this._velocity }
    get drag() { return this._drag === undefined ? 0.075 : this._drag }
    get distribution() { return this._distribution === undefined ? 0.4 : this._distribution }
    set count(v) { this._count = v }
    set gravity(v) { this._gravity = v }
    set velocity(v) { this._velocity = v }
    set drag(v) { this._drag = v }
    set distribution(v) { this._distribution = v }

    get canvas() {
        if(!this._canvas) this._canvas = document.getElementById("confetti") ||
            cT('canvas', {
                id: "confetti",
                append: document.body,
                style: 'position:fixed;top:0; left:0;right:0;bottom:0;pointer-events:none;z-index:10000'
            });
        return this._canvas;
    }

    get ctx() {
        return this.canvas.getContext("2d");
    }

    set width(v) {
        this.canvas.width = v;
    }
    get width() { return this.canvas.width }
    set height(v) {
        this.canvas.height = v;
    }
    get height() { return this.canvas.height }
    get cx() { return this.ctx.canvas.width / 2 }
    get cy() { return this.ctx.canvas.height / 2 }

    get resize()  {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        // cx = ctx.canvas.width / 2;
        // cy = ctx.canvas.height / 2;
    }

    randomRange(min, max) { return Math.random() * (max - min) + min }

    render() {
        this.ctx.clearRect(0, 0, this.width, this.height);

        this.confetti.forEach(this.loop.bind(this));
        if (this.confetti.length > 0) window.requestAnimationFrame(this.render.bind(this));
        // else this.canvas.remove();
    }

    loop(confetto, index) {
        let width = (confetto.dimensions.x * confetto.scale.x);
        let height = (confetto.dimensions.y * confetto.scale.y);

        // Move canvas to position and rotate
        this.ctx.translate(confetto.position.x, confetto.position.y);
        this.ctx.rotate(confetto.rotation);

        // Apply forces to velocity
        confetto.velocity.x -= confetto.velocity.x * this.drag;
        confetto.velocity.y = Math.min(confetto.velocity.y + this.gravity, this.velocity);
        confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

        // Set position
        confetto.position.x += confetto.velocity.x;
        confetto.position.y += confetto.velocity.y;

        // Delete confetti when out of frame
        if (confetto.position.y >= this.canvas.height) {
            this.confetti.splice(index, 1);
            if(this.confetti.length) return;
        }

        // Loop confetto x position
        if (confetto.position.x > this.canvas.width) confetto.position.x = 0;
        if (confetto.position.x < 0) confetto.position.x = this.canvas.width;

        // Spin confetto by scaling y
        confetto.scale.y = Math.cos(confetto.position.y * 0.1);
        this.ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

        // Draw confetto
        this.ctx.fillRect(-width / 2, -height / 2, width, height);

        // Reset transform matrix
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    }


    blast(count, options) {
        if(!options) options = {};
        if("drag" in options) this.drag = options.drag;
        if("gravity" in options)  this.gravity = options.gravity;
        if("velocity" in options) this.velocity = options.velocity;
        this.velocityX = ("velocityX" in options) ? options.velocityX : 25;
        this.velocityY = ("velocityY" in options) ? options.velocityY : 25;
        if("distribution" in options) this.distribution = options.distribution;
        const {promise, resolve} = Promise.create();
        this._promises.push(promise);

        for (let i = 0; i < count; i++) {
            this.confetti.push({
                color: this.colors[Math.floor(this.randomRange(0, this.colors.length))],
                dimensions: {
                    x: this.randomRange(10, 20),
                    y: this.randomRange(10, 30),
                },
                position: {
                    x: this.randomRange(-this.cx, this.cx) * this.distribution + this.cx,
                    y: this.canvas.height - 1,
                },
                rotation: this.randomRange(0, 2 * Math.PI),
                scale: {
                    x: 1,
                    y: 1,
                },
                velocity: {
                    x: this.randomRange(-this.velocityX, this.velocityX),
                    y: this.randomRange(0, -this.velocityY),
                },
            });
        }

        setTimeout(()=>{
            this.render();
            resolve(this);
        }, 0);
        return this.promises;
    }
    get promises() { return Promise.all(this._promises) }
}
